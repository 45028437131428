import { template as template_aa96ee3d02ba4c3ab9b77db2d8244a79 } from "@ember/template-compiler";
const FKLabel = template_aa96ee3d02ba4c3ab9b77db2d8244a79(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
